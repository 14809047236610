
















































































































































import VueBase from '../../VueBase'
import { Component, Watch } from 'vue-property-decorator'
import { ProjectListParams } from './types'
import { formatTimestamp } from '@/utils/utils'
import ProjectEdit from './ProjectEdit.vue'

@Component({
  name: 'ProjectManage',
  components: {
    ProjectEdit,
  },
})
export default class ProjectManage extends VueBase {
  private newEdit: boolean = false
  private pIdObject: any = {}
  private pId: any = ''
  private visible: boolean = false
  private page = 1
  private pageSize = 20
  private total = 0
  private tableData: Array<ProjectListParams> = []
  private searchObj = {
    name: '',
    status: '',
    exclude_vul_status: '',
    project_group_name: '',
  }

  closeDrawer(flag: boolean) {
    this.visible = false
  }

  private levelStatus: any = [
    {
      label: '待确认',
      value: true,
    },
    {
      label: '已确认',
      value: false,
    },
  ]

  levelIcons: any = {
    height: require('../../assets/img/icon_height.png'),
    low: require('../../assets/img/icon_low.png'),

    middle: require('../../assets/img/icon_middle.png'),
    info: require('../../assets/img/icon_info.png'),
  }

  private gotoProjectDetail(e: any, id: any) {
    e.stopPropagation()
    e.preventDefault()
    this.$router.push(`/project/appDetail/${id}`)
  }

  private settingProjectDetail(e: any, id: any) {
    e.stopPropagation()
    e.preventDefault()
    this.newEdit = true
    this.visible = true
    this.$nextTick(() => {
      console.log(' -------------------- ')
      console.log(this.$refs)
      if (this.$refs.projectEditRef) {
        if (this.$refs.projectEditRef.hasOwnProperty('pId')) {
          this.$refs.projectEditRef['pId'] = id // 为其子组件中factoryFormData赋值
        }
      }
    })
    // this.pId = id
    // this.pIdObject = {
    //   pId:id
    // }

    // this.$router.push(`/project/projectEdit/${id}`)
  }

  private getLevel(type: number) {
    switch (type) {
      case 1:
        return 'height'
      case 2:
        return 'middle'
      case 3:
        return 'low'
      case 5:
        return 'info'
      default:
        break
    }
  }
  status: any = [
    {
      name: '正常',
      color: 'var(--color-success)',
      value: 0,
    },
    {
      name: '异常',
      color: 'var(--color-danger)',
      value: 1,
    },
    {
      name: '离线',
      color: '#6B7280',
      value: 2,
    },
  ]

  created() {
    this.getTableData()
    this.getGroups()
  }

  private addNewProject() {
    this.visible = true
  }

  private newSelectData() {
    this.page = 1
    this.getTableData()
  }

  private resetData() {
    this.page = 1

    this.searchObj = {
      name: '',
      status: '',
      exclude_vul_status: '',
      project_group_name: '',
    }
    this.getTableData()
  }

  private currentChange(val: number) {
    this.page = val
    this.getTableData()
  }

  private async getTableData() {
    const params = {
      page: this.page,
      pageSize: this.pageSize,
      ...this.searchObj,
      exclude_vul_status: this.searchObj.exclude_vul_status ? 1 : '',
    }
    this.loadingStart()
    const { status, msg, data, page } = await this.services.project.projectList(
      params
    )
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.tableData = data.reduce(
      (list: Array<ProjectListParams>, item: ProjectListParams) => {
        item.vul_count.sort((a, b) => a.level - b.level)
        list.push({
          ...item,
          latest_time: formatTimestamp(item.latest_time),
        })
        return list
      },
      []
    )
    this.total = page.alltotal
  }
  private async projectDelete(e: any, id: number) {
    e.stopPropagation()
    e.preventDefault()
    this.$msgbox({
      showCancelButton: true,
      cancelButtonText: '取消',
      confirmButtonText: '删除',
      cancelButtonClass: 'cancelButtonClass',
      confirmButtonClass: 'delete-btn',
      showClose: false,
      dangerouslyUseHTMLString: true,
      message: `
        <div class="title">
          <i class="el-icon-warning icon"></i>
          确定删除当前应用?
        </div>
        <div class="tip">
          删除后不可恢复，请谨慎操作
        </div>
      `,
    }).then(async () => {
      this.loadingStart()
      const { status, msg } = await this.services.project.projectDelete({ id })
      this.loadingDone()
      if (status !== 201) {
        this.$message.error('应用删除失败，请重试')
        return
      }
      this.$message.success('应用删除成功')
      this.getTableData()
    })
  }
  private async remoteGroup(query: string) {
    this.getGroups(query)
  }
  private groups: any = []
  private async getGroups(name?: string | undefined) {
    const res = await this.services.authority.getGroupList({
      page: 1,
      pageSize: 50,
      name: name,
    })
    if (res.status === 201) {
      this.groups = res.data
    }
  }
}
